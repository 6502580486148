import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";


export const get_category = createAsyncThunk(
    'category/get_category',
    async ({perPage,page,searchValue,order,includeParent},{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.get(`/category-get?page=${page}&&searchValue=${searchValue}&&perPage=${perPage}&&order=${order}&&includeParent=${includeParent}`, {withCredentials: true})
            
            return fulfillWithValue(data);
        } catch (error){
            return rejectWithValue(error.response.data);
        }
    }
)


export const categoryReducer = createSlice({
    name: 'category',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        categorys: [],
        categorys_all: [],
        totalCategory: 5
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(get_category.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(get_category.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(get_category.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.categorys = payload.categorys
            state.totalCategory = payload.totalCategory
        })
    }
})

export default categoryReducer.reducer

export const {messageClear} = categoryReducer.actions